import Vue from 'vue'
//import Buffer from 'node:buffer'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyWheelView.vue')
    //    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue')
  },
  {
    path: '/mywheel',
    name: 'mywheel',
   
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyWheelView.vue')
  },
  { path: '/unlicense', name: 'unlicense', component: () => import(/* webpackChunkName: "about" */ '../views/UnLicense.vue') }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var x = window.location.origin
    var y = "YUhSMGNEb3ZMMnh2WTJGc2FHOXpkRG80TURndw=="
    var z = "YUhSMGNITTZMeTl6Y0dsdVoyVm5aUzVqYjIwPQ==" 
    var w = "YUhSMGNEb3ZMM053YVc1blpXZGxMbU52YlE9PQ=="
    //if(Buffer.from(x).toString('base64') != Buffer.from(y,'base64').toString("ascii"))
    if(btoa(x)!= atob(z) && btoa(x)!= atob(w) && btoa(x)!= atob(y))
    {
      router.push({ path: '/unlicense', name: 'unlicense', component: () => import(/* webpackChunkName: "about" */ '../views/UnLicense.vue') })
    }
    else
    {
      next()
    }
  } else {
    next()
  }
})

export default router
